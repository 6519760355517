import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
    grid: {
        padding: "0 5px !important",
    }
};

function WhatsApp({ classes, PhoneCode, Phone, ...rest }) {
    // Null checks for PhoneCode and Phone
    const formattedPhoneCode = PhoneCode === 91 ? "91" : "";
    const formattedPhone = Phone ? Phone.toString().replace("+", "") : "";

    // Generate WhatsApp link with null checks using https protocol
    const whatsappLink = `whatsapp://send?phone=${formattedPhoneCode}${formattedPhone}`;
    
    const handleWhatsAppClick = () => {
        window.open(whatsappLink, '_blank', 'noopener,noreferrer');
    };

    return (
        <a
            onClick={handleWhatsAppClick}
            className="whatsapp"
            style={{ cursor: 'pointer' }}
        >
            <i className="fa fa-whatsapp" />
        </a>
    );
}

export default withStyles(style)(WhatsApp);
